import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import {
  PiDotsThreeBold,
  PiPresentation,
  PiPencil,
  PiChartBar,
  PiUsers,
  PiUserCirclePlus,
  PiQrCode,
} from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import {
  formatDate,
  canEditEvent,
  getUrl,
  getCheckInLink,
} from '../../../Utils';
import {
  trackEditProject,
  trackClickProjectAnalytics,
} from '../../../analytics';

import { QRCode, downloadQRCode } from '../../../components/qrCode';
import { URL } from '../../../constants';

const EventBlockOverlay = (props) => {
  const { event, onClickEvent, addClass } = props;

  const eventId = event.eventId;

  const navigate = useNavigate();

  const checkInPageLink = getCheckInLink(event.eventId);

  const items = [
    {
      icon: <PiPresentation />,
      label: 'Video Builder',
      onClick: onClickEvent,
      show: true,
    },
    {
      icon: <PiPencil />,
      label: 'Edit',
      onClick: () => {
        navigate(getUrl(URL.PROJECT_SETTINGS, eventId));
        trackEditProject('Home');
      },
      show: canEditEvent(event),
    },
    {
      icon: <PiChartBar />,
      label: 'Analytics',
      onClick: () => {
        navigate(getUrl(URL.PROJECT_ANALYTICS, eventId));
        trackClickProjectAnalytics('Home');
      },
      show: true,
    },
    {
      icon: <PiUsers />,
      label: 'Hub Page',
      onClick: () => window.open(getUrl(URL.SHARE, eventId), '_blank'),
      show: true,
    },
    {
      icon: <PiUserCirclePlus />,
      label: 'Faces Check-In',
      onClick: () => window.open(checkInPageLink, '_blank'),
      show: true,
    },
    {
      icon: <PiQrCode />,
      label: 'Download Check-In QR',
      onClick: () => downloadQRCode(event, 'Home'),
      show: true,
    },
  ];

  return (
    <div
      className={`absolute top-0 left-0 pointer-events-none sm:hidden group-hover:block ${addClass}`}
    >
      <DropdownButton
        className="event-dropdown absolute top-2 right-2 pointer-events-auto"
        title={<PiDotsThreeBold className="sm:text-xl" />}
        variant=""
        align="end"
      >
        {items.map(
          (item, i) =>
            item.show && (
              <Dropdown.Item
                className="flex items-center gap-2 px-3 py-1.5"
                onClick={item.onClick}
                key={i}
              >
                <div className="text-[1.1rem] leading-none">{item.icon}</div>
                <div className="text-xs">{item.label}</div>
              </Dropdown.Item>
            ),
        )}
      </DropdownButton>

      {event.startDate && event.endDate && (
        <div className="hidden sm:block absolute bottom-0 left-0 px-2 py-1.5 bg-black-overlay-600 text-xs text-white rounded-bl-md rounded-tr-md">
          {formatDate(event.startDate)} – {formatDate(event.endDate)}
        </div>
      )}

      <QRCode event={event} link={checkInPageLink} className="hidden" />
    </div>
  );
};

export default EventBlockOverlay;
