import React, { useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { CaretDown, Check } from '@phosphor-icons/react';
import classNames from 'classnames';

import { Button, Spinner } from './';

type Item = {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  isProcessing?: boolean;
  isCurrent?: boolean;
  show: boolean;
  closeOnClick?: boolean;
  className?: string;
};

type Props = {
  btnData: {
    label: string;
    variant?: string;
    icon?: React.ReactNode;
    withCaret?: boolean;
    className?: string;
  };
  menuHeading?: string;
  menuItems: Item[];
  position?: 'right' | 'left';
};

export const Dropdown = (props: Props) => {
  const { btnData, menuHeading, menuItems, position } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpenDropdown = Boolean(anchorEl);

  const handleClickDropdown = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClickDropdown}
        variant={btnData.variant ?? 'primary'}
        isThin
        withIcon={btnData.withCaret}
        className={btnData.className}
      >
        {btnData.icon}
        <div>{btnData.label}</div>
        {btnData.withCaret && <CaretDown weight="bold" />}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={isOpenDropdown}
        onClose={handleCloseDropdown}
        transformOrigin={{
          horizontal: position ?? 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: position ?? 'right',
          vertical: 'bottom',
        }}
        className="mt-2"
        sx={{
          '.MuiMenuItem-root': { padding: '0.5rem 1rem' },
          '.MuiList-root:has(.MuiMenuItem-root:hover) .MuiMenuItem-root:not(:hover)':
            { backgroundColor: 'transparent' },
          '.MuiMenuItem-root.Mui-selected': {
            backgroundColor: 'var(--light-grey-2)',
          },
          '.MuiMenuItem-root:hover': {
            backgroundColor: 'var(--light-grey-1) !important',
          },
        }}
      >
        {menuHeading && (
          <React.Fragment>
            <div className="px-4 text-sm font-bold">{menuHeading}</div>

            <hr className="my-2" />
          </React.Fragment>
        )}

        {menuItems.map(
          (item: Item) =>
            item.show && (
              <MenuItem
                onClick={() => {
                  item.onClick && item.onClick();
                  if (item.closeOnClick) handleCloseDropdown();
                }}
                disabled={item.disabled}
                className={classNames(item.className, 'transition-all')}
                selected={item.isCurrent}
              >
                {item.icon && (
                  <ListItemIcon className="text-[1.25rem]">
                    {item.isProcessing ? (
                      <Spinner size="sm" hoverColor="white" />
                    ) : (
                      item.icon
                    )}
                  </ListItemIcon>
                )}

                <ListItemText>
                  <div className="text-sm">{item.label}</div>
                </ListItemText>

                {item.isCurrent && (
                  <Check weight="bold" className="ml-2 float-end" />
                )}
              </MenuItem>
            ),
        )}
      </Menu>
    </React.Fragment>
  );
};
