import React from 'react';
import classNames from 'classnames';
import { PiCaretLeft } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { getVBLinkParts } from '../Utils';
import { trackClickBackButton } from '../analytics';
import { URL } from '../constants';
import { useEventStore } from '../stores/event';

const BackBtn = ({
  onClick,
  label,
  className,
}: {
  onClick?: () => void;
  label?: string;
  className?: string;
}) => {
  const currentEvent = useEventStore((state) => state.currentEvent);

  const navigate = useNavigate();

  const openVB = () => {
    const { eventId, page } = getVBLinkParts(window.location.pathname);

    navigate(
      `/${eventId}${currentEvent.isAssigneeAccess ? URL.VB_ASSIGNEE : URL.VIDEO_BUILDER}`,
    );

    trackClickBackButton(page, currentEvent);
  };

  return (
    <button
      onClick={() => (onClick ? onClick() : openVB())}
      className={classNames(
        'w-full lg:w-min flex items-center mb-6 sm:mb-10 lg:mb-2 px-[5vw] py-4 lg:p-2 gap-2 whitespace-nowrap lg:rounded-md border-y border-grey-200 border-solid lg:border-0 hover:bg-grey-100',
        className,
      )}
    >
      <PiCaretLeft />
      <div>{label ?? 'Back'}</div>
    </button>
  );
};

export default BackBtn;
