import React, { useState, useMemo } from 'react';
import { Collapse } from 'react-bootstrap';
import { PiCaretDown, PiCaretRight } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from '../../../Utils';
import { trackHomeClickViewAllProjects } from '../../../analytics';
import { URL } from '../../../constants';
import { Event } from '../../../types';

import EventBlock from './eventBlock';

const ProjectsContainer = (props: {
  heading: string;
  events: Event[];
  isCollapse?: boolean;
  hideHeading?: boolean;
  projectNumLimit?: number;
}) => {
  const {
    heading,
    events,
    isCollapse,
    hideHeading,
    projectNumLimit = Infinity,
  } = props;

  const [collapseState, setCollapseState] = useState<boolean>(true);

  const navigate = useNavigate();

  const eventsUpd = useMemo(() => {
    return events.length > projectNumLimit
      ? events.slice(0, projectNumLimit - 1)
      : events;
  }, [events, projectNumLimit]);

  if (isEmpty(events)) return null;

  const isShow = isCollapse ? collapseState : true;

  return (
    <div className="w-full flex flex-col gap-4">
      {isCollapse ? (
        <div
          className="flex items-center p-2 gap-2 rounded-md cursor-pointer hover:bg-grey-100"
          onClick={() => setCollapseState(!collapseState)}
        >
          <div className="text-[1.1rem] leading-none">
            {isShow ? <PiCaretDown /> : <PiCaretRight />}
          </div>
          <h5>{heading}</h5>
        </div>
      ) : (
        !hideHeading && <h5>{heading}</h5>
      )}

      <Collapse in={isShow}>
        <div id="collapse-wrapper">
          <div className="flex flex-wrap gap-4 sm:gap-7">
            {eventsUpd.map((event) => (
              <EventBlock event={event} key={event.eventId} />
            ))}

            {events.length > projectNumLimit && (
              <div
                className="w-[var(--event-block-width)] h-min aspect-video flex items-center justify-center text-lg uppercase rounded-md border-1 border-grey-900 border-solid hover:border-primary-900 hover:text-primary-900 cursor-pointer"
                onClick={() => {
                  navigate(URL.DASH_PROJECTS);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  trackHomeClickViewAllProjects(heading);
                }}
              >
                View All
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default ProjectsContainer;
