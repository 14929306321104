import React from 'react';
import classNames from 'classnames';

import { isEmpty } from '../../../Utils';

import StatsBlock from '../../VideoBuilder/Share/components/statsBlock';

const StatsWithListBlock = (props: any) => {
  const {
    heading,
    currentEvent,
    generatedPeople,
    statsBlockShowPages,
    listHeading,
    listEmptyText,
    list,
    listClass,
    listStyle,
    itemClass,
    itemStyle,
  } = props;

  return (
    <div className="flex flex-col p-4 gap-4 rounded-md border-1 border-grey-200 border-solid">
      <div className="text-xl font-bold">{heading}</div>

      <div className="flex flex-col sm:flex-row gap-4 sm:gap-12 overflow-hidden">
        <StatsBlock
          event={currentEvent}
          people={generatedPeople}
          showPages={statsBlockShowPages}
        />

        <div className="min-w-0 flex flex-col grow basis-0 gap-2 text-sm">
          <div className="font-bold">{listHeading}</div>

          {isEmpty(list) && (
            <div className="text-black/[0.5] italic">{listEmptyText}</div>
          )}

          <div
            className={classNames(
              listClass,
              'flex flex-col flex-wrap gap-x-8 gap-y-1',
            )}
            style={listStyle ?? {}}
          >
            {list.map((person: any, index: number) => (
              <div
                key={person.elementId}
                className={classNames(itemClass, 'flex px-1 gap-2', {
                  'bg-grey-100': (index + 1) % 2,
                })}
                style={itemStyle ?? {}}
              >
                <div className="w-5">{index + 1}.</div>

                <div className="grow basis-0 whitespace-nowrap overflow-hidden text-ellipsis">
                  {person.name}
                </div>

                {person.number && (
                  <div className="ml-auto">{person.number}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsWithListBlock;
