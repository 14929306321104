import React from 'react';
import classNames from 'classnames';
import { PiCheck } from 'react-icons/pi';

import { ButtonType } from '../types';
import PopoverTrigger from './popoverTrigger';
import { Spinner } from './';

type Props = {
  children: React.ReactNode;
  id?: string;
  onClick: any;
  type?: ButtonType;

  // styles
  variant?: string;
  form?: string;
  isThin?: boolean;
  withIcon?: boolean;
  className?: string;

  // disabled
  disabled?: boolean;
  allowPointerEvents?: boolean;

  // submit
  isSubmitBtn?: boolean;
  isProcessing?: boolean;
  isDone?: boolean;
  spinnerSize?: string;
  spinnerColor?: string;
  spinnerHoverColor?: string;

  // popover
  popoverHeader?: string;
  popoverBody?: string;
  popoverPlacement?: string;
  hidePopover?: boolean;
  isThinPopover?: boolean;
};

export const Button = (props: Props) => {
  const {
    children,
    id,
    onClick = () => {},
    type = undefined,
    variant = 'primary',
    form = 'rec',
    isThin = false,
    withIcon = false,
    className = '',
    disabled = false,
    allowPointerEvents = false,
    isSubmitBtn = false,
    isProcessing = false,
    isDone = false,
    spinnerSize = 'lg',
    spinnerColor = 'white',
    spinnerHoverColor = '',
    popoverHeader = '',
    popoverBody = '',
    popoverPlacement = 'top',
    hidePopover = false,
    isThinPopover = false,
  } = props;

  const btnClass = classNames(
    {
      'group flex items-center justify-center transition-all': true,
      'disabled:pointer-events-none': !allowPointerEvents,
      'disabled:opacity-65':
        variant !== 'square-icon' &&
        variant !== 'link' &&
        variant !== 'animated-border',
      'min-w-32 h-[var(--form-el-height)]':
        !isThin && variant !== 'square-icon' && variant !== 'link',

      // variants
      'bg-primary-900 text-white hover:opacity-80': variant === 'primary',
      'bg-gray-500 text-white hover:opacity-80': variant === 'secondary',
      'bg-primary-50 text-primary-900 border-1 border-primary-translucent border-solid hover:border-primary-900':
        variant === 'pink',
      'border-1 border-grey-200 border-solid hover:border-primary-900':
        variant === 'outline',
      'border-1 border-black border-solid hover:bg-black hover:text-white':
        variant === 'black-outline',
      'border-1 border-grey-200 border-solid hover:bg-grey-200':
        variant === 'grey-outline',
      'hover:bg-black hover:text-white': variant === 'transparent-black',
      'bg-black text-white hover:opacity-80': variant === 'black',
      'bg-grey-100 text-black hover:bg-grey-200': variant === 'light-grey',
      'w-10 h-10 bg-grey-100 text-[1.25rem] text-primary-900 hover:bg-gradient-primary-bottom hover:text-white disabled:opacity-40':
        variant === 'square-icon',
      'bg-blue-green text-white hover:opacity-80': variant === 'blue-green',
      'bg-white text-primary-900 hover:opacity-80': variant === 'white',
      'border-1 border-white border-solid text-true-white enabled:hover:bg-white enabled:hover:text-black':
        variant === 'white-outline',
      'text-true-white hover:bg-white hover:text-primary-900':
        variant === 'transparent-white',
      'inline-block p-0 underline cursor-pointer text-primary-900 hover:opacity-60':
        variant === 'link',
      'hover:bg-grey-100': variant === 'transparent',
      'relative p-[2px] disabled:opacity-65': variant === 'animated-border',

      // form
      'px-3 py-1.5':
        form !== 'square' &&
        variant !== 'link' &&
        variant !== 'animated-border' &&
        variant !== 'custom',
      'rounded-md': form === 'rec' || form === 'square',
      'aspect-square p-1.5': form === 'square',
      'rounded-full': form === 'pill',
      'h-10': isThin,
      'gap-2': withIcon,
    },
    className,
  );

  const btn = (
    <button
      id={id}
      type={type ?? isSubmitBtn ? 'submit' : 'button'}
      className={btnClass}
      onClick={onClick}
      disabled={disabled || isProcessing}
    >
      {isSubmitBtn ? (
        <React.Fragment>
          <div
            className={`absolute transition-opacity duration-500 leading-none ${!isProcessing && !isDone ? 'opacity-100' : 'opacity-0'}`}
          >
            {children}
          </div>

          <Spinner
            size={spinnerSize}
            color={spinnerColor}
            hoverColor={spinnerHoverColor}
            className={`absolute transition-opacity duration-500 ${isProcessing ? 'opacity-100' : 'opacity-0'}`}
          />

          <PiCheck
            className={`absolute transition-opacity duration-500 ${isDone ? 'opacity-100' : 'opacity-0'}`}
            size="1.25rem"
          />
        </React.Fragment>
      ) : (
        children
      )}
    </button>
  );

  if (popoverHeader || popoverBody)
    return (
      <PopoverTrigger
        content={btn}
        placement={popoverPlacement ?? 'top'}
        hidePopover={hidePopover}
        popoverHeader={popoverHeader}
        popoverBody={popoverBody}
        isThinPopover={isThinPopover}
      />
    );

  return btn;
};
