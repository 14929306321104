import React, { useState, useEffect } from 'react';

import {
  PiHouseLine,
  PiPresentation,
  PiPresentationFill,
  PiPencilSimple,
  PiPencilSimpleFill,
  PiChartBar,
  PiChartBarFill,
  PiFolderOpen,
  PiFolderOpenFill,
  PiUsers,
  PiUserCirclePlus,
  PiQrCode,
  PiDownloadSimple,
} from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { getUrl, getCheckInLink } from '../../Utils';

import {
  trackOpenProject,
  trackEditProject,
  trackClickProjectAnalytics,
} from '../../analytics';

import { URL } from '../../constants';

import { useEventStore } from '../../stores/event';

import { QRCode, downloadQRCode } from '../qrCode';
import SideNavBigBtn from './sideNavBigBtn';
import SideNavBtn from './sideNavBtn';
import TabPanel from './tabPanel';

const ProjectSideNav = () => {
  const currentEvent = useEventStore((state) => state.currentEvent);

  const [panelIndex, setPanelIndex] = useState(-1);

  const eventId = currentEvent?.eventId ?? '';
  const checkInPageLink = getCheckInLink(eventId) ?? '';

  const pathname = window.location.pathname;

  const navigate = useNavigate();

  // handle showing and hiding tab panel
  useEffect(() => {
    const handleHover = (event: any) => {
      let isActionDone = false;

      btns.forEach((btn, index) => {
        const tabElement = document.getElementById(
          `project-sidenav-tab-${index}`,
        );

        if (tabElement?.contains(event.target)) {
          if (panelIndex === -1 || panelIndex !== index) setPanelIndex(index);

          isActionDone = true;
        }
      });

      if (!isActionDone && panelIndex !== -1)
        btns.forEach((btn, index) => {
          const panelElement = document.getElementById(
            `project-sidenav-tabpanel-${index}`,
          );

          if (panelIndex === index && !panelElement?.contains(event.target))
            setPanelIndex(-1);
        });
    };

    document.addEventListener('mouseover', handleHover);
    return () => document.removeEventListener('mouseover', handleHover);
  }, [panelIndex]);

  const assetsBtns = [
    {
      label: 'Hub Page',
      icon: <PiUsers />,
      href: getUrl(URL.SHARE, eventId),
      isNewTab: true,
    },
    {
      label: 'Faces Check-In',
      icon: <PiUserCirclePlus />,
      href: checkInPageLink,
      isNewTab: true,
    },
    {
      label: 'Download Check-In QR',
      icon: <PiQrCode />,
      onClick: () => downloadQRCode(currentEvent, 'Side Nav'),
      extraIcon: <PiDownloadSimple />,
    },
  ];

  const btns = [
    {
      icon: <PiPresentation data-testid="vb-icon" />,
      currentIcon: <PiPresentationFill data-testid="vb-icon-fill" />,
      label: 'Video Builder',
      url: getUrl(URL.VIDEO_BUILDER, eventId),
      onClick: () => trackOpenProject('side_nav', currentEvent),
    },
    {
      icon: <PiPencilSimple data-testid="edit-icon" />,
      currentIcon: <PiPencilSimpleFill data-testid="edit-icon-fill" />,
      label: 'Edit',
      url: getUrl(URL.PROJECT_SETTINGS, eventId),
      onClick: () => trackEditProject('Side Nav'),
    },
    {
      icon: <PiChartBar data-testid="edit-icon" />,
      currentIcon: <PiChartBarFill data-testid="edit-icon-fill" />,
      label: 'Analytics',
      url: getUrl(URL.PROJECT_ANALYTICS, eventId),
      onClick: () => trackClickProjectAnalytics('Side Nav'),
    },
    {
      icon: <PiFolderOpen data-testid="assets-icon" />,
      currentIcon: <PiFolderOpenFill data-testid="assets-icon-fill" />,
      label: 'Assets',
      content: (
        <React.Fragment>
          <div className="font-bold mb-2">Project Assets and Quick Links</div>

          {assetsBtns.map((btn) => (
            <SideNavBtn
              key={btn.label}
              icon={btn.icon}
              text={btn.label}
              href={btn.href}
              onClick={btn.onClick}
              isNewTab={btn.isNewTab}
              extraIcon={btn.extraIcon}
            />
          ))}
        </React.Fragment>
      ),
    },
  ];

  return (
    <aside
      id="main-side-nav"
      className="fixed top-[var(--header-height)] left-0 h-full flex px-[var(--page-spacing)] py-[var(--content-vertical-spacing)] gap-8 z-40 transition-transform bg-true-white lg:bg-transparent"
      aria-label="SideNav"
    >
      <div className="w-min h-full flex flex-col items-center overflow-y-auto">
        <SideNavBigBtn
          icon={<PiHouseLine />}
          text="Home"
          onClick={() => navigate(URL.HOME)}
        />

        <hr className="w-14 my-2" />

        {btns.map((btn, index) => (
          <SideNavBigBtn
            key={btn.label}
            index={index}
            onClick={() => {
              btn.url && navigate(btn.url);
              btn.onClick && btn.onClick();
            }}
            icon={btn.icon}
            currentIcon={btn.currentIcon}
            text={btn.label}
            isCurrent={btn.url && pathname.includes(btn.url)}
          />
        ))}
      </div>

      <div className="absolute top-0 left-[calc(var(--page-spacing)_+_3.5rem)] h-screen flex items-center">
        {btns.map(
          (btn, index) =>
            btn.content && (
              <TabPanel key={btn.label} panelIndex={panelIndex} index={index}>
                {btn.content}
              </TabPanel>
            ),
        )}
      </div>

      <QRCode event={currentEvent} link={checkInPageLink} className="hidden" />
    </aside>
  );
};

export default ProjectSideNav;
