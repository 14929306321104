import React, { FC, useMemo, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { Collapse } from 'react-bootstrap';
import {
  PiQuestion,
  PiVideo,
  PiFileZip,
  PiCalendarBlank,
} from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import {
  trackUGModalOpen,
  trackOnboardingModalOpen,
  trackQuickstartPackDownload,
  trackSampleProjectOpen,
} from '../../../analytics';

import OnboardingVideoModal from '../../../components/modals/onboardingVideoModal';
import UserGuidingModal from '../../../components/modals/userGuidingModal';
import PageContainer from '../../../components/pageContainer';

import { URL, DEMO_ID, EVENT_TYPE_DEMO, ZIP_URL } from '../../../constants';

import { useEventStore } from '../../../stores/event';
import { useUserStore } from '../../../stores/user';
import { Event } from '../../../types';

import { demoFifa } from '../../VideoBuilder/components/demoData';

import ProjectsContainer from '../components/projectsContainer';
import CreateEventBtn from './createEventBtn';

const Home: FC = () => {
  const user = useUserStore((state) => state.user);
  const userEvents: Event[] = useEventStore((state) => state.userEvents);
  const setEventId = useEventStore((state) => state.setEventId);

  const [isLMCollapsed, setIsLMCollapsed] = useState<boolean>(true);
  const [showOnboardingVideo, setShowOnboardingVideo] = useState(false);
  const [showUGModal, setShowUGModal] = useState(false);

  const navigate = useNavigate();

  const { isNewUser, recentEvents, completedEvents } = useMemo(() => {
    let isNewUser = false;
    const recentEvents: Event[] = [];
    const completedEvents: Event[] = [];

    userEvents.forEach((event) => {
      if (event.type !== EVENT_TYPE_DEMO) {
        if (event.stage === 'generation') completedEvents.push(event);
        else recentEvents.push(event);
      }
    });

    if (!completedEvents.length && !recentEvents.length) isNewUser = true;

    return { isNewUser, recentEvents, completedEvents };
  }, [userEvents]);

  useEffect(() => {
    setEventId(null);
  }, [setEventId]);

  useEffect(() => {
    if (isNewUser) setShowUGModal(true);
  }, [isNewUser]);

  const iconClass = 'w-8 h-8 sm:w-10 sm:h-10';

  const learningBlocks = [
    {
      image:
        'https://t3.ftcdn.net/jpg/05/24/43/88/360_F_524438877_PZFzwc5OWJ3MTWQVFfHKwu1DRVMaSgPx.jpg',
      icon: <PiQuestion className={iconClass} />,
      label: 'How does it work?',
      onClick: () => {
        setShowUGModal(true);
        trackUGModalOpen();
      },
    },
    {
      image:
        'https://cdn.pixabay.com/photo/2022/09/21/17/02/blue-background-7470781_640.jpg',
      icon: <PiVideo className={iconClass} />,
      label: 'Watch onboarding video',
      onClick: () => {
        setShowOnboardingVideo(true);
        trackOnboardingModalOpen();
      },
    },
    {
      image:
        'https://st4.depositphotos.com/1741969/29457/i/450/depositphotos_294571810-stock-photo-blackground-of-abstract-glitter-lights.jpg',
      icon: <PiFileZip className={iconClass} />,
      label: 'Download the quickstart pack',
      onClick: () => {
        window.location.href = ZIP_URL.QUICKSTART;
        trackQuickstartPackDownload('Learning materials');
      },
    },
    {
      image: '/static/media/afl-event-thumbnail.79e329cc90e61b383f77.jpeg',
      icon: <PiCalendarBlank className={iconClass} />,
      label: 'Play with Sample Project',
      onClick: () => {
        const demoData = localStorage.getItem('demoData');
        if (!demoData)
          localStorage.setItem('demoData', JSON.stringify(demoFifa));

        trackSampleProjectOpen();

        navigate(`/${DEMO_ID.FIFA}${URL.VIDEO_BUILDER}`);
      },
    },
  ];

  const renderLearningMaterials = (isCollapse: boolean) => {
    const isShow = isLMCollapsed || !isCollapse;

    return (
      <div className="w-full">
        {isCollapse && (
          <Collapse in={!isShow}>
            <div id="collapse-wrapper">
              <div className="flex justify-center">
                <button
                  className="px-4 py-2 rounded-md bg-grey-100 hover:bg-grey-200"
                  onClick={() => setIsLMCollapsed(!isLMCollapsed)}
                >
                  Learn More
                </button>
              </div>
            </div>
          </Collapse>
        )}

        <Collapse in={isShow}>
          <div id="collapse-wrapper">
            <div className="w-full flex flex-col gap-4">
              <h5 className="m-0">Don't know where to start?</h5>

              <div className="flex flex-wrap gap-4 sm:gap-6">
                {learningBlocks.map((block) => {
                  const thumbnailClass =
                    'w-[var(--event-block-width)] sm:w-[calc((var(--page-width)_-_(1.5rem_*_5))_/_6)] aspect-[1/0.9] rounded-md';

                  return (
                    <button
                      className="group relative"
                      onClick={block.onClick}
                      key={block.label}
                    >
                      <img
                        className={`${thumbnailClass} absolute top-0 left-0 object-cover`}
                        src={block.image}
                        alt="Learning block"
                      />

                      <div
                        className={`${thumbnailClass} absolute bg-black-overlay-500 transition-all group-hover:bg-black`}
                      />

                      <div
                        className={`${thumbnailClass} relative flex flex-col items-center justify-center text-center p-3 sm:p-4 gap-2 text-white transition-all group-hover:scale-110`}
                      >
                        {block.icon}

                        <div>{block.label}</div>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  const MAX_PROJECTS = 10;

  return (
    <PageContainer title="Home" addClass="gap-8">
      {/* welcome box */}
      <div className="animated-box-purple w-full flex flex-col items-center justify-center text-center p-4 sm:p-6 gap-4 sm:gap-6 text-white rounded-md animate-running-5">
        <div className="text-xl sm:text-2xl font-bold uppercase">
          Welcome {isNewUser ? parse('to CrowdClip&reg;') : 'back'},{' '}
          {user.firstName ?? 'amazing human'}!
        </div>

        <div>
          Let's get started on a better way to generate hundreds of personalised
          videos for your events.
        </div>

        <CreateEventBtn />
      </div>

      {/* content */}
      {isNewUser ? (
        <React.Fragment>
          {renderLearningMaterials(false)}

          <h5 className="w-full m-0">Recent projects</h5>

          <span className="w-full text-grey-900 font-bold text-center">
            Your projects will appear here after creating one
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderLearningMaterials(true)}

          <ProjectsContainer
            heading="Recent projects"
            events={recentEvents}
            projectNumLimit={MAX_PROJECTS}
          />

          <ProjectsContainer
            heading="Completed projects"
            events={completedEvents}
            isCollapse
            projectNumLimit={MAX_PROJECTS}
          />
        </React.Fragment>
      )}

      <OnboardingVideoModal
        isNewUser={false}
        showOnboardingVideo={showOnboardingVideo}
        setShowOnboardingVideo={setShowOnboardingVideo}
      />

      <UserGuidingModal
        show={showUGModal}
        onHide={() => setShowUGModal(false)}
      />
    </PageContainer>
  );
};

export default Home;
