import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, getDemoSelected } from '../../../Utils';
import { sendStepCompleteEmail } from '../../../UtilsEmail';

import {
  trackUploadDemo,
  trackClickUploadMore,
  trackClipsDeleteClip,
} from '../../../analytics';

import { Alert, ProgressBar } from '../../../components';
import BackBtn from '../../../components/backBtn';
import ErrorModal from '../../../components/modals/errorModal';
import PageHeader from '../../../components/pageHeader';
import PageSpinner from '../../../components/pageSpinner';
import SellingPoint from '../../../components/sellingPoint';
import UploadInfoPoints from '../../../components/uploadInfoPoints';

import { DEMO_ID } from '../../../constants';

import { getCreationResults } from '../../../services/api/creations';
import { fileDelete } from '../../../services/api/files';
import { queuesDelete } from '../../../services/api/queues';
import { getUploadedSelects } from '../../../services/selectGroupsService';

import { useEventStore } from '../../../stores/event';
import { useUserStore } from '../../../stores/user';

import DoneBtn from '../components/doneBtn';
import FileDropArea from '../components/fileDropArea';
import MobileReminderBox from '../components/mobileReminderBox';
import PageWithSideBar from '../components/pageWithSideBar';
import ThumbnailsContainer from '../components/thumbnailsContainer';
import UploadMoreBtn from '../components/uploadMoreBtn';
import { getClipsText } from './components/clipsText';
import DemoComponent from './components/demoComponent';

const Clips = () => {
  const eventId = useParams()?.eventId;
  const uploadRef = useRef(null);

  const [clips, setClips] = useState([]);
  const [uploadingClipsObj, setUploadingClipsObj] = useState({});
  const [deletingClips, setDeletingClips] = useState([]);
  const [filesNum, setFilesNum] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasCreations, setHasCreations] = useState(false);

  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);
  const [errorHeading, setErrorHeading] = useState('Something went wrong');

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);
  const [demoClips, setDemoClips] = useState(demoData.clips ?? []);

  const assigneeData = JSON.parse(localStorage.getItem('assigneeData')) ?? {};

  const user = useUserStore((state) => state.user);
  const eventIdLocal = useEventStore((state) => state.eventId);
  const setEventId = useEventStore((state) => state.setEventId);
  const setDemoEvent = useEventStore((state) => state.setDemoEvent);
  const currentEvent = useEventStore((state) => state.currentEvent);

  const isPublic = useMemo(
    () => currentEvent?.isAssigneeAccess,
    [currentEvent?.isAssigneeAccess],
  );

  // set event id on mount
  useEffect(() => {
    if (isDemo) setDemoEvent(eventId);
    else if (eventIdLocal !== eventId) setEventId(eventId, true);
  }, [eventId, eventIdLocal, isDemo, setDemoEvent, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      let pageError = currentEvent.error ?? null;

      if (
        currentEvent.isAssigneeAccess &&
        !assigneeData[currentEvent.eventId]?.accessPages.includes('clips')
      )
        pageError = 403;

      if (pageError) setPageError(pageError);
      else {
        await fetchClipsData();
        await fetchCreationsData();
      }

      setIsLoading(false);
    };

    if (currentEvent) {
      if (isDemo) {
        if (demoData.isClipsUploaded) setClips(getDemoSelected(demoClips));
        setIsLoading(false);
      } else onMount();
    }
  }, [currentEvent]);

  const fetchClipsData = async () => {
    try {
      const uploadedClips = await getUploadedSelects(eventId, isPublic);

      setClips(uploadedClips);
    } catch (err) {
      console.error(`Error fetching the clips: ${err}`);
      setError('Error fetching the clips');
    }
  };

  const fetchCreationsData = async () => {
    try {
      const creations = await getCreationResults(eventId, true);

      setHasCreations(!isEmpty(creations));
    } catch (err) {
      console.error(`Error fetching the creations: ${err}`);
      setError('Error fetching the creations');
    }
  };

  const handleUploadStart = (files) => {
    setIsUploading(true);
    setShowUpload(false);

    const uploadingClipsTemp = { ...uploadingClipsObj };

    files.forEach((file) => {
      uploadingClipsTemp[file.uid] = { name: file.name, progress: 0 };
    });

    setUploadingClipsObj(uploadingClipsTemp);
  };

  const handleUploadComplete = () => {
    fetchClipsData();
  };

  const handleError = (file) => {
    if (file.type.startsWith('image/')) {
      setError(
        `${file.name} file upload failed. Ensure the file is in .MP4 or .MOV video format.`,
      );
    } else {
      setErrorHeading('Oops!');
      setError(
        `It looks like there's an issue with your upload due to connectivity problems. We recommend refreshing the page and uploading your clips in small batches.`,
      );
    }
  };

  // deletes file and queues associated with it
  const deleteClip = async (video) => {
    try {
      // add elementId to list of deleting clips
      setDeletingClips([...deletingClips, video.elementId]);

      await queuesDelete(video.elementId, isPublic);
      await fileDelete(video.elementId, isPublic);
      await fetchClipsData();

      trackClipsDeleteClip(currentEvent);
    } catch {
      toast.error('Something went wrong. Try again later');
    }
  };

  const handleUploadMore = (e, position) => {
    setShowUpload(true);

    if (!isDemo)
      setTimeout(() => {
        uploadRef.current?.upload?.uploader?.onClick(e);
      });

    trackClickUploadMore('clips', currentEvent, position);
  };

  const handleDemoClips = (id, isAdd) => {
    const clips = [...demoClips];
    const foundClip = clips.find((clip) => clip.elementId === id);

    foundClip.isSelected = isAdd;

    if (!noClips) setClips(getDemoSelected(clips));

    if (!isAdd) trackClipsDeleteClip(currentEvent);

    setDemoClips(clips);
    localStorage.setItem('demoData', JSON.stringify({ ...demoData, clips }));
  };

  const uploadingClips = useMemo(() => {
    const clipsIds = clips.map((clips) => clips.elementId);

    const uploadingClips = Object.keys(uploadingClipsObj).map(
      (id) => uploadingClipsObj[id],
    );

    const filteredUploading = uploadingClips
      .filter((clip) => clip && !clipsIds.includes(clip.elementId))
      .filter((clip) => !deletingClips.includes(clip.elementId));

    const selectedDemoClips = getDemoSelected(demoClips);

    if (isEmpty(filteredUploading) && isUploading) {
      // clear uploading states
      setUploadingClipsObj({});
      setIsUploading(false);

      toast.success('All clips are uploaded!', {
        toastId: 'clips-upload-success',
      });

      localStorage.setItem(
        'uploadData',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('uploadData')),
          clipsCount: isDemo ? selectedDemoClips.length : clips.length,
        }),
      );

      // for demo – track clips upload with num of selected clips
      if (isDemo) trackUploadDemo('clips', selectedDemoClips.length);
      // send email to the user that the upload is complete
      else if (!isPublic)
        sendStepCompleteEmail(user, currentEvent, "Clips' upload");
    }

    return uploadingClips;
  }, [
    clips,
    currentEvent,
    deletingClips,
    demoClips,
    isDemo,
    isPublic,
    isUploading,
    uploadingClipsObj,
    user,
  ]);

  const isFilteredUploading = !isEmpty(uploadingClips);

  const noClips = useMemo(() => {
    return (isEmpty(clips) && !isFilteredUploading) || showUpload;
  }, [clips, isFilteredUploading, showUpload]);

  const {
    heading,
    subheading,
    generatedAlertText,
    progress,
    infoPoints,
    tipText,
  } = getClipsText({
    noClips,
    uploadingClips,
    finishedClips: clips,
    filesNum,
    hasCreations,
    orientation: currentEvent?.videoMode,
    isDemo,
    handleUploadMore,
    demoData,
    isPublic,
  });

  const allowUploadMore = useMemo(() => {
    return !isDemo || !demoData.isLimited;
  }, [demoData.isLimited, isDemo]);

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`Clips – ${currentEvent?.name}`}
      isPageContainer
    >
      <BackBtn label="Video Builder" />

      <PageWithSideBar
        mainContent={
          <>
            <PageHeader heading={heading} subheading={subheading} />

            {noClips ? (
              // upload clips view
              <React.Fragment>
                <DemoComponent
                  demoClips={demoClips}
                  setClips={setClips}
                  setUploadingClipsObj={setUploadingClipsObj}
                  setFilesNum={setFilesNum}
                  handleDemoClips={handleDemoClips}
                  handleUploadStart={handleUploadStart}
                />

                <FileDropArea
                  type="clips"
                  onDrop={handleUploadStart}
                  onProgress={(event) => {
                    setUploadingClipsObj((previousClips) => ({
                      ...previousClips,
                      [event.id]: event,
                    }));
                  }}
                  onComplete={handleUploadComplete}
                  onError={handleError}
                  setFilesNum={setFilesNum}
                  uploadRef={uploadRef}
                  isDemo={isDemo}
                />
              </React.Fragment>
            ) : isFilteredUploading ? (
              // uploading clips view
              <div className="flex flex-col gap-4 text-center">
                <ProgressBar progress={progress} />

                <div className="font-bold uppercase">Uploading</div>
              </div>
            ) : !isEmpty(clips) ? (
              // uploaded clips view
              <React.Fragment>
                <ThumbnailsContainer
                  videos={clips}
                  deletingClips={deletingClips}
                  deleteClip={deleteClip}
                  isBig
                  isDemo={isDemo}
                  handleDemoClips={handleDemoClips}
                />

                <div className="flex flex-col sm:flex-row gap-4">
                  <DoneBtn addClass={allowUploadMore ? 'sm:w-1/2' : ''} />

                  {allowUploadMore && (
                    <UploadMoreBtn
                      onClick={() => handleUploadMore('Bottom button')}
                    >
                      Upload More
                    </UploadMoreBtn>
                  )}
                </div>
              </React.Fragment>
            ) : null}
          </>
        }
        sideContent={
          <>
            {generatedAlertText && (
              <Alert generatedAlertText={generatedAlertText} />
            )}

            {tipText && !isDemo && <SellingPoint text={tipText} />}

            <UploadInfoPoints
              points={infoPoints}
              isInfo={!noClips && !isFilteredUploading}
            />

            {!isDemo && !isPublic && <MobileReminderBox />}
          </>
        }
        showDemoTipText={
          isDemo
            ? noClips || isFilteredUploading
              ? 'upload'
              : 'summary'
            : null
        }
      />

      <ErrorModal
        show={!!error}
        heading={errorHeading}
        subheading={error}
        isTryAgainBtn
      />
    </PageSpinner>
  );
};

export default Clips;
