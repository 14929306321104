import { round, getPct, getDuration } from '../../../Utils';
import { File, Face, GeneratedPerson } from '../../../types';

export const calculateClipsStats = (uploadedClips: File[]) => {
  let size = 0,
    duration = 0;

  uploadedClips.forEach((clip) => {
    if (clip.parameters) {
      size += (clip.size || 0) / 1024 / 1024 / 1024;
      duration += clip.parameters?.length || 0;
    }
  });

  return {
    clipsSize: round(size, true),
    clipsDuration: getDuration(duration, true),
  };
};

export const calculateKeyStats = (
  generatedPeople: GeneratedPerson[],
  hlReel: File,
  isDonation: boolean,
) => {
  let fpDataCount = 0,
    playsTotal = 0,
    engCount = 0,
    paymentSum = 0;

  const engPeople: { elementId: string; name: string; number: number }[] = [];
  const payees: Face[] = [];

  generatedPeople.forEach(({ face, metrics }) => {
    // first-party data
    fpDataCount +=
      (!!face.email ? 1 : 0) +
      (!!face.name ? 1 : 0) +
      (!!face.instagram ? 1 : 0) +
      (!!face.customFieldData ? 1 : 0);

    // assets play total
    playsTotal +=
      (metrics.viewedCount ?? 0) * (hlReel?.parameters?.length ?? 0);

    // engagement
    const engNum =
      (metrics.viewedCount ?? 0) +
      (metrics.downloadedCount ?? 0) +
      (metrics.sharedCount ?? 0);

    engPeople.push({
      elementId: face.elementId,
      name: face.name,
      number: engNum,
    });
    if (engNum > 0) engCount++;

    // payment
    if (face?.payment?.paid) {
      payees.push(face);
      paymentSum += face.payment.amount;
    }
  });

  return {
    fpDataCount,
    playsTotal,
    topEngPeople: engPeople
      .filter((p) => p.number > 0)
      .sort((one, other) => other.number - one.number)
      .slice(0, 10),
    engPct: getPct(engCount / generatedPeople.length) || 0,
    payees: formatPayees(payees, isDonation),
    paymentSum,
    paymentStyles: calculatePaymentStyles(payees),
  };
};

const formatPayees = (payees: Face[], isDonation: boolean) => {
  payees.sort((one, other) => {
    if (!one.payment || !other.payment) return 1;

    // sort by amount from highest to lowest
    if (other.payment.amount !== one.payment.amount)
      return other.payment.amount - one.payment.amount;

    // for by date from oldest to newest
    return (
      new Date(one.payment.date).getTime() -
      new Date(other.payment.date).getTime()
    );
  });
  return payees.map((payee) => ({
    elementId: payee.elementId,
    name: payee.name,
    number: isDonation ? `$${payee.payment?.amount ?? 0}` : null,
  }));
};

const calculatePaymentStyles = (payees: Face[]) => {
  const minRows = 5;
  const maxCols =
    window.innerWidth < 640 ? 1 : window.innerWidth < 1024 ? 2 : 4;
  const rowsNum = Math.max(minRows, Math.ceil(payees.length / maxCols));
  const colsNum = Math.ceil(payees.length / rowsNum);

  return {
    payeesContainer: {
      height: `${0.25 * (rowsNum - 1) + 0.875 * 1.5 * rowsNum}rem`,
    },
    payeeBox: { width: `calc((100% - (4rem * ${colsNum - 1})) / ${colsNum})` },
  };
};
